import lookups from "./lookups";
import { MembershipPaymentPlanScheduleFormStages } from "./membershipPaymentPlanSchedule";
import {
  MembershipInitialPeriod,
  MembershipLife,
  MembershipPeriod,
  Months,
  PaymentOption,
} from "../js/enums";
import { PaymentMethodFormStages } from "./paymentMethod";
import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";

export const MembershipPaymentPlanFormStages = [
  {
    title: "",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4m_name",
            className: "col-md-6",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4m_productvariantid",
            hidden: true,
            label: "Product variant",
          },
          {
            name: "g4m_sequence",
            className: "col-md-6",
            description: "",
            label: "Sequence",
            type: "int",
          },
          {
            name: "g4m_membershiplife",
            className: "col-md-4",
            description: "",
            label: "Membership life",
            required: true,
            type: "picklist",
            enum: MembershipLife,
          },
          {
            name: "g4m_membershipperiod",
            className: "col-md-4",
            description: "",
            label: "Membership period",
            required: true,
            type: "picklist",
            enum: MembershipPeriod,
          },
          {
            name: "g4m_contractualperiod",
            className: "col-md-4",
            description: "",
            label: "Contractual period",
            required: true,
            type: "picklist",
            enum: MembershipPeriod,
          },
          {
            name: "g4m_paymentmethodid",
            allowAdd: true,
            allowEdit: true,
            className: "col-md-6",
            description:
              "The finance payment method used to collect repeat payments",
            displayIfFromValues: (values) =>
              (String(values.g4m_membershipperiod) ===
                String(MembershipPeriod.Month) ||
                String(values.g4m_membershipperiod) ===
                  String(MembershipPeriod.Year)) &&
              String(values.g4m_membershiplife) ===
                String(MembershipLife.Indefinite),
            label: "Payment Method",
            lookup: lookups.g4b_paymentmethod.finance,
            subFormStages: PaymentMethodFormStages,
            type: "lookup",
          },
          {
            name: "g4m_paymentoption",
            className: "col-md-6",
            description:
              "If set to ProRata then the ProRata membership fee end date will be set to a day before the renewal date. Otherwise the duration will be equal to the membership period",
            displayIfFromValues: (values) =>
              (String(values.g4m_membershipperiod) ===
                String(MembershipPeriod.Month) ||
                String(values.g4m_membershipperiod) ===
                  String(MembershipPeriod.Year)) &&
              String(values.g4m_membershiplife) ===
                String(MembershipLife.Indefinite) &&
              values.g4m_paymentmethodid,
            label: "Payment option",
            type: "picklist",
            enum: PaymentOption,
          },
          {
            name: "g4m_membershipperiodduration",
            className: "col-12",
            description:
              "The length of the membership in units of Membership Period.",
            displayIfFromValues: (values) =>
              String(values.g4m_membershiplife) ===
                String(MembershipLife.Month) ||
              String(values.g4m_membershiplife) ===
                String(MembershipLife.Year),
            label: "Membership Period Duration",
            type: "int",
          },
          {
            name: "g4m_enddate",
            className: "col-12",
            dateFormat: "dd/MM/yyyy",
            description: "",
            displayIfFromValues: (values) =>
              String(values.g4m_membershiplife) ===
              String(MembershipLife.FixedEndDate),
            label: "End date",
            type: "datetime",
          },
          {
            name: "g4m_endmonth",
            className: "col-12",
            description: "",
            displayIfFromValues: (values) =>
              String(values.g4m_membershiplife) ===
              String(MembershipLife.Year),
            label: "End Month",
            type: "picklist",
            enum: Months,
          },
          {
            name: "g4m_proratathreshold",
            className: "col-12",
            description:
              "This will determine how many days into the purchase month are before the cut off for setting the membership end date to a year after purchase date ",
            displayIfFromValues: (values) =>
              (String(values.g4m_membershiplife) ===
                String(MembershipLife.Year) &&
                values.g4m_endmonth) ||
              String(values.g4m_membershipperiod) ===
                String(MembershipPeriod.Month),
            label: "Pro Rata Threshold (Days)",
            type: "int",
          },
          {
            name: "g4m_setenddatetoendofmonth",
            className: "col-12",
            description:
              "Set the membership end date to end of relavant end of month",
            displayIfFromValues: (values) =>
              values.g4m_membershiplife &&
              String(values.g4m_membershiplife) !==
                String(MembershipLife.FixedEndDate),
            label: "Set end date to end of month",
            type: "bit",
          },
          {
            name: "g4m_membershipinitialperiod",
            className: "col-md-4",
            description: "",
            label: "Initial period",
            type: "picklist",
            enum: MembershipInitialPeriod,
          },
          {
            name: "g4m_membershipinitialperiodduration",
            className: "col-md-4",
            description: "",
            label: "Initial period duration",
            type: "int",
          },
          {
            name: "g4m_showzerovaluejoiningfee",
            className: "col-md-4",
            label: "Show zero value joining fee",
            type: "bit",
          },
        ],
      },
      {
        name: "",
        fields: [
          {
            className: "col-12 mb-3",
            name: "g4b_channel",
            displayAltDescription: (_, parentFormState) =>
                (parentFormState && parentFormState.g4b_channel)
                  ? "These are the sales channels through which the payment plan is available. The channels listed are the ones channels selected for the product. If no channels are checked, then this membership payment plan will be applied to all displayed channels."
                  : "These are the sales channels through which the payment plan is available. If no channels are checked, then this membership payment plan will be applied to all displayed channels.",
            filterLookupOptions: (
              lookupOptions,
              values,
              parentFormState
            ) => {
              if(parentFormState && parentFormState.g4b_channel){
                return lookupOptions.filter((x) =>
                  parentFormState.g4b_channel.includes(x.Key)
                );
              }
              else { 
                return lookupOptions;
              }
            },
            label: "Sales channels",
            inline: true,
            lookup: lookups.g4b_channel.all,
            type: "checklist",
          },
        ],
      },
      {
        name: "Schedule",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            {
              name: "g4m_startdate",
              className: "col-date",
              dateFormat: "DD/MM/yyyy",
              type: "datetime",
            },
            {
              name: "g4m_enddate",
              className: "col-date",
              dateFormat: "DD/MM/yyyy",
              type: "datetime",
            },
            {
              name: "g4m_joiningfee",
              className: "col-number",
              type: "money",
            },
            {
              name: "g4m_periodfee",
              className: "col-number",
              type: "money",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              filterByParent: true,
              name: "g4m_membershippaymentplanid",
            },
            {
              name: "g4m_startdate",
              type: "datetime",
            },
            {
              name: "g4m_enddate",
              type: "datetime",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          initialOrderedBy: "g4m_startdate",
          initialOrderedByAsc: true,
          linkedEntityId: "g4m_membershippaymentplanid",
          loadStateRelatedEntityName:
            "g4m_membershippaymentplanschedule",
          lookup: lookups.g4m_membershippaymentplanschedule.all,
          subFormStages: MembershipPaymentPlanScheduleFormStages,
          useAlphabetPagination: false,
          useSubForm: true,
        },
      },
    ],
  },
];
