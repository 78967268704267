import {
  BookingStatus,
  NoPurchaserReason,
  PaymentStatus,
  DonationStatus,
} from "../js/enums";
import lookups from "./lookups";
import BookingAddresses from "../components/form/booking/BookingAddresses";
import ResendBookingConfirmation from "../components/form/booking/ResendBookingConfirmation";
import Summary from "../components/form/booking/Summary";
import {
  CommunicationActivityStatus,
  OverviewColumn,
  ProductGroupType,
} from "../js/enums";
import { BookingPaymentFormStages } from "./bookingPayment";
import { BookingProductFormStages } from "./bookingProduct";
import { BookingTicketFormStages } from "./bookingTicket";
import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";
import { VehicleRegistrationNumberFormStages } from "./vehicleRegistrationNumber";
import { administratorRoles } from "../js/roles";
import { DonationFormStages } from "./donation";

export const BookingFormStages = [
  {
    title: "",
    id: "summary",
    overviewColumn: OverviewColumn.Main,
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "bookingSummary",
            className: "col-12",
            component: Summary,
            displayComponentOnOverview: true,
            type: "component",
          },
          {
            name: "g4b_bookingreference",
            description: "",
            hidden: true,
            label: "Reference",
            type: "nvarchar",
          },
          {
            name: "g4b_bookingstatus",
            description: "",
            enum: BookingStatus,
            hidden: true,
            label: "Status",
            type: "picklist",
          },
          {
            name: "g4b_channelidname",
            description: "",
            hidden: true,
            label: "Channel",
            type: "nvarchar",
          },
          {
            name: "bookingtotalprice",
            description: "",
            hidden: true,
            label: "Total Price",
            type: "money",
          },
        ],
      },
    ],
  },
  {
    title: "Booking Products",
    id: "booking-products",
    overviewColumn: OverviewColumn.Main,
    displayEditButtonFromState: () => false,
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: true,
          hideNameColumn: true,
          fields: [
            {
              name: "g4b_protoproductidname",
              label: "Booking Product",
              className: "col-short-name",
            },
            {
              name: "g4m_membershippaymentplanidname",
              label: "Membership Payment Plan",
              className: "col-short-name",
              linkedEntityId: "g4m_membershippaymentplanid",
              linkForNewRecord: "/membership-payment-plan",
            },
            {
              name: "g4b_number",
              label: "Quantity",
              className: "col-number",
              type: "string",
            },
            {
              name: "g4b_bookingprice",
              label: "Booking Price",
              className: "col-number",
              type: "money",
            },
            {
              name: "g4m_membershipinitialfee",
              label: "Membership Initial Fee",
              className: "col-number",
              type: "money",
            },
            {
              name: "g4m_membershipjoiningfee",
              label: "Membership Joining Fee",
              className: "col-number",
              type: "money",
            },
            {
              name: "g4b_discountvalue",
              label: "Discount Value",
              className: "col-number",
              type: "money",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          loadStateRelatedEntityName: "g4b_bookingproduct",
          lookup: lookups.g4b_bookingproduct.all,
          subFormStages: BookingProductFormStages,
          useSubForm: true,
        },
      },
    ],
  },
  {
    title: "Booking Payments",
    id: "booking-payments",
    overviewColumn: OverviewColumn.Main,
    displayEditButtonFromState: () => false,
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: true,
          fields: [
            {
              name: "g4b_paymentmethodidname",
              label: "Payment Method",
              className: "col-short-name",
            },
            {
              name: "g4b_paymentamount",
              label: "Payment Amount",
              className: "col-number",
              type: "money",
            },
            {
              name: "g4b_bookingoperatoridname",
              label: "Booking Operator",
              className: "col-short-name",
            },
            {
              name: "g4b_terminalidname",
              label: "Terminal",
              className: "col-short-name",
            },
            {
              name: "g4b_paymentstatus",
              className: "col-name",
              label: "Payment Status",
              type: "statusbadge",
              enum: PaymentStatus,
            },
            {
              name: "g4b_cardauthorisationcode",
              label: "Card Authorisation Code",
              className: "col-short-name",
            },
            {
              name: "g4b_cardnumber",
              label: "Card Number",
              className: "col-short-name",
            },
            {
              name: "g4b_discountcode",
              label: "Discount Code",
              className: "col-short-name",
            },
            {
              name: "g4b_vouchernumber",
              label: "Voucher Number",
              className: "col-short-name",
            },
            {
              name: "g4b_paymentmandateidname",
              label: "Payment Mandate",
              className: "col-short-name",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          loadStateRelatedEntityName: "g4b_bookingpayment",
          lookup: lookups.g4b_bookingpayment.all,
          subFormStages: BookingPaymentFormStages,
          useSubForm: true,
        },
      },
    ],
  },
  {
    title: "Product Users",
    id: "product-users",
    overviewColumn: OverviewColumn.Main,
    displayEditButtonFromState: () => false,
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: false,
          hideNameColumn: true,
          fields: [
            {
              name: "g4b_contactidname",
              label: "Contact Name",
              className: "col-short-name",
            },
            {
              name: "g4b_membershipnumber",
              label: "Membership Number",
              className: "col-short-name",
              linkedEntityId: "g4m_membershipid",
              linkForNewRecord: "/membership",
            },
            {
              name: "g4b_productbaseidname",
              label: "Product",
              className: "col-short-name",
              linkedEntityId: "g4b_productbaseid",
              linkForNewRecord: "/product",
            },
            {
              name: "g4b_varianttypeidname",
              label: "Variant",
              className: "col-short-name",
            },
            {
              name: "g4b_startdate",
              className: "col-date",
              label: "Start Date",
              type: "datetime",
            },
            {
              name: "g4b_enddate",
              className: "col-date",
              label: "End Date",
              type: "datetime",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          loadStateRelatedEntityName: "g4b_productuser",
          lookup: lookups.g4b_productuser.all,
          useSubForm: false,
        },
      },
    ],
  },
  {
    title: "Booking Tickets",
    id: "booking-tickets",
    overviewColumn: OverviewColumn.Main,
    displayEditButtonFromState: () => false,
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: true,
          hideNameColumn: true,
          fields: [
            {
              name: "g4b_utid",
              label: "UTID",
              className: "col-short-name",
              type: "string",
            },
            {
              name: "g4b_barcode",
              label: "Barcode",
              className: "col-short-name",
            },
            {
              name: "g4b_printed",
              className: "col-bool",
              label: "Is Printed?",
            },
            {
              name: "g4b_iscancelled",
              className: "col-bool",
              label: "Is Cancelled?",
            },
            {
              name: "g4b_whitelisted",
              className: "col-bool",
              label: "Is Whitelisted?",
            },
            {
              name: "g4b_blacklisted",
              className: "col-bool",
              label: "Is Blacklisted?",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          loadStateRelatedEntityName: "g4b_ticket",
          lookup: lookups.g4b_ticket.all,
          linkForNewRecord: "/booking-ticket",
          subFormStages: BookingTicketFormStages,
          useSubForm: false,
        },
      },
    ],
  },
  {
    title: "Communication Activities",
    id: "communication-activities",
    overviewColumn: OverviewColumn.Main,
    displayEditButtonFromState: () => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "resendBookingConfirmation",
            className: "col-12",
            component: ResendBookingConfirmation,
            displayComponentOnOverview: true,
            type: "component",
          },
        ],
        table: {
          allowAdd: false,
          allowEdit: false,
          fields: [
            {
              name: "subject",
              label: "Subject",
              className: "col-short-name",
            },
            {
              name: "g4c_communicationname",
              label: "Communication",
              className: "col-short-name",
            },
            {
              name: "statecode",
              label: "Activity Status",
              className: "col-short-name",
              type: "statusbadge",
              enum: CommunicationActivityStatus,
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          initialOrderedBy: "createdon",
          initialOrderedByAsc: false,
          loadStateRelatedEntityName: "g4c_communicationactivity",
          lookup: lookups.g4c_communicationactivity.all,
          useSubForm: false,
        },
      },
    ],
  },
  {
    title: "Communication Booking Tags",
    id: "communication-booking-tags",
    overviewColumn: OverviewColumn.Main,
    displayEditButtonFromState: () => false,
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: false,
          fields: [
            {
              name: "g4b_grouptype",
              label: "Type",
              className: "col-name",
              type: "picklist",
              enum: ProductGroupType,
            },
            {
              name: "g4b_order",
              label: "Order",
              className: "col-number",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          initialOrderedBy: "g4b_order",
          initialOrderedByAsc: true,
          loadStateRelatedEntityName: "g4b_productgroup",
          lookup: lookups.g4b_productgroup.all,
          useSubForm: false,
        },
      },
    ],
  },
  {
    title: "Delivery and Payment Addresses",
    id: "delivery-and-payment-addresses",
    overviewColumn: OverviewColumn.Main,
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "bookingAddresses",
            className: "col-12",
            component: BookingAddresses,
            displayComponentOnOverview: true,
            type: "component",
          },
        ],
      },
    ],
  },
  {
    title: "Purchaser",
    id: "purchaser",
    overviewColumn: OverviewColumn.Side,
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_contactid",
            hidden: true,
            type: "nvarchar",
          },
          {
            name: "g4b_contactidname",
            className: "col-md-6",
            description: "",
            displayIfFromState: (state) => state.g4b_contactid,
            label: "Name",
            linkedEntityId: "g4b_contactid",
            linkForNewRecord: "/contact",
            type: "nvarchar",
          },
          {
            name: "emailaddress1",
            className: "col-md-6",
            description: "",
            displayIfFromState: (state) => state.g4b_contactid,
            label: "Email",
            type: "nvarchar",
          },
          {
            name: "telephone1",
            className: "col-md-6",
            description: "",
            displayIfFromState: (state) => state.g4b_contactid,
            label: "Business Phone",
            type: "nvarchar",
          },
          {
            name: "telephone2",
            className: "col-md-6",
            description: "",
            displayIfFromState: (state) => state.g4b_contactid,
            label: "Home Phone",
            type: "nvarchar",
          },
          {
            name: "mobilephone",
            className: "col-md-6",
            description: "",
            displayIfFromState: (state) => state.g4b_contactid,
            label: "Mobile Phone",
            type: "nvarchar",
          },
          {
            name: "g4b_nopurchaserreason",
            className: "col-md-12",
            description: "",
            displayIfFromState: (state) =>
              !state.g4b_contactid && state.g4b_nopurchaserreason,
            enum: NoPurchaserReason,
            label: "No Purchaser Reason",
            type: "picklist",
          },
        ],
      },
    ],
  },
  {
    title: "History",
    overviewColumn: OverviewColumn.Side,
    id: "booking-history",
    displayEditButtonFromState: (_) => false,
    displayIfFromState: (state) =>
      state.g4b_nextbookingid || state.g4b_previousbookingid,
    editOnOverview: false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_nextbookingidname",
            className: "col-6",
            description: "",
            label: "Next Booking",
            linkedEntityId: "g4b_nextbookingid",
            linkForNewRecord: "/booking",
            type: "nvarchar",
          },
          {
            name: "g4b_previousbookingidname",
            className: "col-6",
            description: "",
            label: "Previous Booking",
            linkedEntityId: "g4b_previousbookingid",
            linkForNewRecord: "/booking",
            type: "nvarchar",
          },
          {
            name: "g4b_initialoperatoridname",
            className: "col-6",
            description: "",
            label: "Initial Operator",
            type: "nvarchar",
          },
        ],
      },
    ],
  },
  {
    title: "Values",
    overviewColumn: OverviewColumn.Side,
    id: "booking-values",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_taxamount",
            className: "col-6",
            description: "",
            label: "Tax Amount",
            type: "money",
          },
          {
            name: "g4b_donation",
            className: "col-6",
            description: "",
            label: "Donation",
            type: "money",
          },
          {
            name: "g4b_commission",
            className: "col-12",
            description: "",
            label: "Commission",
            type: "money",
          },
          {
            name: "g4b_refundvalue",
            className: "col-6",
            description: "",
            label: "Refunded",
            type: "money",
          },
          {
            name: "g4b_unrefundvalue",
            className: "col-6",
            description: "",
            label: "Unrefunded",
            type: "money",
          },
        ],
      },
    ],
  },
  {
    title: "Discount",
    overviewColumn: OverviewColumn.Side,
    id: "discount",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_discountvalue",
            className: "col-6",
            description: "",
            label: "Discount Value",
            type: "money",
          },
          {
            name: "g4b_discountreason",
            className: "col-6",
            description: "",
            label: "Discount Reason",
            type: "nvarchar",
          },
          {
            name: "g4b_discountcode",
            className: "col-6",
            description: "",
            label: "Discount Code",
            type: "nvarchar",
          },
          {
            className: "col-6",
            name: "g4b_promotionidname",
            description: "",
            label: "Promotion",
            type: "nvarchar",
          },
        ],
      },
    ],
  },
  {
    title: "Question Answers",
    id: "question-answers",
    overviewColumn: OverviewColumn.Main,
    displayEditButtonFromState: () => false,
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: false,
          allowStatusEdit: false,
          hideNameColumn: false,
          fields: [
            {
              name: "g4_questionidname",
              className: "col-short-name",
              label: "Question",
              type: "string",
            },
            {
              name: "g4_answergroupoptionidname",
              className: "col-short-name",
              label: "Answer Option Selected",
              type: "string",
            },
            {
              name: "g4_answertext",
              className: "col-short-name",
              label: "Text Answer",
              type: "string",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          loadStateRelatedEntityName: "g4_questionanswer",
          lookup: lookups.g4_questionanswer.all,
          useSubForm: false,
        },
      },
    ],
  },
  {
    title: "Donations",
    id: "booking-donations",
    overviewColumn: OverviewColumn.Main,
    displayEditButtonFromState: () => false,
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: true,
          fields: [
            {
              name: "g4d_declarationidname",
              className: "col-short-name",
              label: "Declaration",
              type: "string",
            },
            {
              name: "g4d_fundidname",
              className: "col-short-name",
              label: "Fund",
              type: "string",
            },
            {
              name: "g4d_donationstatus",
              className: "col-short-name",
              label: "Donation Status",
              type: "statusbadge",
              enum: DonationStatus,
            },
            {
              name: "g4d_donationvalue",
              className: "col-short-name",
              label: "Donation value",
              type: "money",
            },
            {
              name: "g4d_giftaidvalue",
              className: "col-short-name",
              label: "Gift Aid Value",
              type: "money",
            },
            {
              name: "g4m_membershipidname",
              className: "col-short-name",
              label: "Membership",
              type: "string",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          initialOrderedBy: "createdon",
          initialOrderedByAsc: false,
          loadStateRelatedEntityName: "g4d_donation",
          lookup: lookups.g4d_donation.all,
          subFormStages: DonationFormStages,
          useSubForm: true,
        },
      },
    ],
  },
  {
    title: "Vehicle Registration Numbers",
    id: "booking-vehicle-registration-numbers",
    overviewColumn: OverviewColumn.Main,
    displayEditButtonFromState: () => false,
    sections: [
      {
        name: "",
        table: {
          allowAdd: true,
          allowAddRoles: administratorRoles,
          allowEdit: true,
          allowStatusEdit: true,
          statusEditAllowedRoles: administratorRoles,
          hideNameColumn: false,
          fields: [
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          loadStateRelatedEntityName: "g4b_vehicleregistrationnumber",
          linkedEntityId: "g4b_bookingid",
          lookup: lookups.g4b_vehicleregistrationnumber.all,
          subFormStages: VehicleRegistrationNumberFormStages,
          useSubForm: true,
        },
      },
    ],
  },
];
